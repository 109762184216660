// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
// require("channels")
require("chartkick/chart.js")
require("turbolinks").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



function getElementsByQuerySelector(querySelector) {
    return Array.prototype.slice.call(
        document.querySelectorAll(querySelector)
    );
}

function burgerClickListener() {
    // Get the target from the "data-target" attribute
    // const target = el.getAttribute('data-target');
    const target = this.dataset.target;
    const $target = document.getElementById(target);

    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    this.classList.toggle('is-active');
    $target.classList.toggle('is-active');
}

function enableBurgerMenu() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll('.navbar-burger'),
        0
    );

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

        // Add a click event on each of them
        $navbarBurgers.forEach( el => {
            el.addEventListener('click', burgerClickListener);
        });
    }
}

function setFileName() {
    if (this.files.length > 0) {
        const fileName = this.nextElementSibling.nextElementSibling;
        fileName.textContent = this.files[0].name;
    }
}

function displayFileNames() {
    const fileInputs = getElementsByQuerySelector('.file input[type=file]')

    fileInputs.forEach( el => {
        el.onchange = setFileName
    });
}

document.addEventListener("direct-upload:initialize", event => {
    const { target, detail } = event
    const { id, file } = detail
    const progressContainer = document.getElementById('progress-container')
    progressContainer.insertAdjacentHTML("afterbegin", `
    <div class="column">
        <div id="direct-upload-${id}">
            Upload progress: <span id="direct-upload-text-progress-${id}">preparing...</span>
            <progress id="direct-upload-progress-${id}" class="progress is-link" max="100">0%</progress>
        </div>
    </div>
    `)
})

document.addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    const progressTextElement = document.getElementById(`direct-upload-text-progress-${id}`)
    if (progress < 100) {
        progressTextElement.textContent = `${Math.round(progress)}%`
        progressElement.setAttribute('value', `${progress}`)
    } else {
        progressTextElement.textContent = 'finishing...'
        progressElement.removeAttribute('value')
    }

})

document.addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const progressContainer = document.getElementById('progress-container')
    progressContainer.insertAdjacentHTML("beforeend", `
    <div class="notification is-danger">
        <strong>Upload Error:</strong> ${error}
    </div>
    `)
})

document.addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    const progressTextElement = document.getElementById(`direct-upload-text-progress-${id}`)
    progressTextElement.textContent = 'finishing...'
    progressElement.removeAttribute('value')
})

function enableModals() {
    const rootEl = document.documentElement;
    const $modals = getElementsByQuerySelector('.modal')
    const $modalButtons = getElementsByQuerySelector('.modal-button')
    const $modalCloses = getElementsByQuerySelector('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button')

    if ($modalButtons.length > 0) {
        $modalButtons.forEach(function ($el) {
            $el.addEventListener('click', function () {
                const target = $el.dataset.target;
                openModal(target);
            });
        });
    }

    if ($modalCloses.length > 0) {
        $modalCloses.forEach(function ($el) {
            $el.addEventListener('click', function () {
                closeModals();
            });
        });
    }

    function openModal(target) {
        const $target = document.getElementById(target);
        rootEl.classList.add('is-clipped');
        $target.classList.add('is-active');
    }

    function closeModals() {
        rootEl.classList.remove('is-clipped');
        $modals.forEach(function ($el) {
            $el.classList.remove('is-active');
        });
    }

    document.addEventListener('keydown', function (event) {
        const e = event || window.event;
        if (e.keyCode === 27) {
            closeModals();
        }
    });
}

document.addEventListener('turbolinks:load', enableBurgerMenu);
document.addEventListener('turbolinks:load', displayFileNames);
document.addEventListener('turbolinks:load', enableModals);

function enableLightbox() {
    const lightboxables = getElementsByQuerySelector('.lightboxable')

    if (lightboxables.length === 0) { return }

    const lightbox = document.getElementById('lightbox')
    lightbox.addEventListener('click', function() {
        lightbox.style.display = 'none'
        document.body.style.cursor = 'default'
    })

    lightboxables.forEach(lightboxable => {
        lightboxable.addEventListener('mouseover', () => {
            document.body.style.cursor = 'pointer'
        })

        lightboxable.addEventListener('mouseout', () => {
            document.body.style.cursor = 'default'
        })

        lightboxable.addEventListener('click', function(event) {
            event.preventDefault()

            const lightboxImageElement = document.getElementById('lightbox-image')
            // const lightboxImageWebpSourceElement = document.getElementById('lightbox-webp-source')
            if (lightboxable.dataset.lightboxsrc) {
                lightboxImageElement.setAttribute('src', lightboxable.dataset.lightboxsrc)
            }
            if (lightboxable.dataset.lightboxsrcset) {
                lightboxImageElement.setAttribute('srcset', lightboxable.dataset.lightboxsrcset)
            }
            // if (lightboxable.dataset.lightboxwebpsrcset) {
            //     lightboxImageWebpSourceElement.setAttribute('srcset', lightboxable.dataset.lightboxwebpsrcset)
            // }

            lightbox.addEventListener('mouseover', () => {
                document.body.style.cursor = 'pointer'
            })

            lightbox.addEventListener('mouseout', () => {
                document.body.style.cursor = 'default'
            })

            lightbox.style.display = 'flex'
        });
    });
}
document.addEventListener('turbolinks:load', enableLightbox);

document.addEventListener('turbolinks:load', () => {
    const buttons =  getElementsByQuerySelector('.clipboard-copy-button')

    for (const button of buttons) {
        const targetElement = document.getElementById(button.dataset.copytarget)
        if (targetElement) {
            if (targetElement.nodeName === 'INPUT') {
                const targetContent = targetElement.value
                if (targetContent && targetContent !== '') {
                    button.removeAttribute('disabled')
                    button.onclick = function() {
                        button.textContent = 'done'
                        navigator.clipboard.writeText(targetContent)
                            .catch(err => {
                                console.error(err)
                            })
                        setTimeout(() => {
                            button.textContent = 'copy'
                        }, 2000)
                    }
                }
            }
        }
    }
})


// fix turbolinks messing up autoplay
;(function () {
    let each = Array.prototype.forEach
    let autoplayIds = []

    document.addEventListener('turbolinks:before-cache', function () {
        let autoplayElements = document.querySelectorAll('[autoplay]')
        each.call(autoplayElements, function (element) {
            if (!element.id) throw 'autoplay elements need an ID attribute'
            autoplayIds.push(element.id)
            element.removeAttribute('autoplay')
        })
    })

    document.addEventListener('turbolinks:before-render', function (event) {
        autoplayIds = autoplayIds.reduce(function (ids, id) {
            let autoplay = event.data.newBody.querySelector('#' + id)
            if (autoplay) autoplay.setAttribute('autoplay', true)
            else ids.push(id)
            return ids
        }, [])
    })
})()

function setInitialSecond(event) {
    const video = event.currentTarget

    if (!video.dataset.currenttime) { return }
    if (video.dataset.playlistprogresstrackurl) { return }

    video.currentTime = video.dataset.currenttime
    video.dataset.currenttime = null
}

function saveVideoSecond(event) {
    const video = event.currentTarget
    const currentTime = Math.round(video.currentTime)

    if (parseInt(video.dataset.lastsavedsecond) !== currentTime) {
        sendVideoSecond(video)
    }
}

function saveVideoSecondRespectingRatelimit(event) {
    const video = event.currentTarget
    const currentTime = Math.round(video.currentTime)
    const lastSavedSecond = parseInt(video.dataset.lastsavedsecond)

    if ((lastSavedSecond + 30) <= currentTime) {
        sendVideoSecond(video)
    }
}

function sendVideoSecond(video) {
    const currentTime = Math.round(video.currentTime)
    video.dataset.lastsavedsecond = currentTime.toString()

    const params = '?second=' + currentTime
    const xhr = new XMLHttpRequest()
    xhr.open(
        'POST',
        video.dataset.progresstrackurl + params,
        true
    )
    xhr.send()

    video.dataset.lastsavedsecond = Math.round(video.currentTime).toString()
}

function startVideoProgressTracker() {
    const video = document.getElementsByTagName('video')[0]
    if (!video) { return }
    if (!video.dataset.progresstrackurl) { return }

    // video.addEventListener('play', setInitialSecond)
    video.addEventListener('loadedmetadata', setInitialSecond)

    video.addEventListener('timeupdate', saveVideoSecondRespectingRatelimit)

    video.addEventListener('ended', saveVideoSecond)
    video.addEventListener('pause', saveVideoSecond)
    video.addEventListener('seeked', saveVideoSecond)
}

document.addEventListener('turbolinks:load', startVideoProgressTracker);



function getElementsByQuerySelector(querySelector) {
    return Array.prototype.slice.call(
        document.querySelectorAll(querySelector)
    );
}

function getVideos() {
    const playlistData = document.getElementById('playlist-data')
    return JSON.parse(playlistData.dataset.playlist)
}

function trackPlaylistProgress() {
    const video = document.getElementsByTagName('video')[0]
    if (!video) { return }

    const progressTrackUrl = video.dataset.playlistprogresstrackurl
    if (!progressTrackUrl) { return }

    const hash = window.location.hash.substr(1)
    const current =  video.dataset.current

    const videoID = hash || current
    if (!videoID) { return }

    const params = '?video=' + videoID
    const xhr = new XMLHttpRequest()
    xhr.open('POST', progressTrackUrl + params, true)
    xhr.send()
}

function playNextVideo() {
    const player = getPlayer()
    if (!player) {
        return
    }
    if (!player.dataset.next) {
        return
    }
    if (player.dataset.next === player.dataset.current) {
        return
    }

    setMarkedMediaObject(player.dataset.next)

    const videos = getVideos()

    let video = null
    let nextVideo = null
    let videoIndex = 0
    videos.forEach(el => {
        if (el['id'] === player.dataset.next) {
            video = el
            nextVideo = videos[videoIndex + 1]
        }
        videoIndex++
    })

    if (video) {
        setUpVideoPlayer(
            video['title'],
            video['id'],
            video['source']['src'],
            video['poster'],
            'y',
            video['progressTrackUrl'],
            true
        )

        if (nextVideo) {
            player.dataset.next = nextVideo['id']
        }
    } else {
        player.onended = function() {}
    }
}

function startVideoPlaylists() {
    const player = getPlayer()
    if (!player) {
        return
    }
    if (player.dataset.next) {
        return
    }

    if (player.dataset.current) {
        return
    }

    if (player.dataset.nostart === 'y') {
        return
    }

    const videos = getVideos()

    let video = videos[0]
    let nextVideo = videos[1]

    const hash = window.location.hash.substr(1)
    const progressID = player.dataset.playlistprogress
    const wantedId = progressID || hash
    if (wantedId) {
        let vIndex = 0
        videos.forEach(v => {
            vIndex++
            if (v['id'] === wantedId) {
                video = v
                nextVideo = videos[vIndex]
            }
        })
    }

    markMediaObject(video['id'])

    setUpVideoPlayer(
        video['title'],
        video['id'],
        video['source']['src'],
        video['poster'],
        null,
        video['progressTrackUrl'],
        false
    )

    if (nextVideo) {
        player.dataset.next = nextVideo['id']

        player.onended = function () {
            playNextVideo()
        }
    }
}

function getPlayer() {
    return document.getElementById('playlisted-video')
}

function setUpVideoPlayer(videoTitle, videoID, videoSrc, videoPoster, noStart, progressTrackUrl, autoplay) {
    document.getElementById('playlist-video-title').textContent = videoTitle
    const player = getPlayer()
    player.dataset.current = videoID
    player.removeAttribute('preload')
    if (autoplay) {
        player.setAttribute('autoplay', 'autoplay')
    }
    player.src = videoSrc
    player.poster = videoPoster
    player.dataset.nostart = noStart ? noStart : ''
    if (progressTrackUrl) {
        player.dataset.progresstrackurl = progressTrackUrl
    }

    window.location.hash = videoID

    trackPlaylistProgress()
    // startVideoProgressTracker()
}

function setMarkedMediaObject(videoID) {
    unmarkMediaObjects()
    markMediaObject(videoID)
}

function markMediaObject(videoID) {
    const mediaObject = document.getElementById('video-' + videoID)
    mediaObject.classList.toggle('has-text-danger')
}

function unmarkMediaObjects() {
    const playlistItems = getElementsByQuerySelector('.playlist-item')

    if (playlistItems.length > 0) {
        playlistItems.forEach( el => {
            el.classList.remove('has-text-danger')
        });
    }
}

function setPlaylistClickEvents() {
    const playlistItems = getElementsByQuerySelector('.playlist-item')

    if (playlistItems.length > 0) {
        playlistItems.forEach( el => {
            el.addEventListener('click', function(event) {
                event.preventDefault()

                const player = document.getElementById('playlisted-video')
                player.dataset.next = this.dataset.hashid

                playNextVideo()
            });
        });
    }
}

document.addEventListener('turbolinks:load', startVideoPlaylists);
document.addEventListener('turbolinks:load', setPlaylistClickEvents);